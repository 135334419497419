<!-- ======================= PAGE CONTENT ====================================== -->
<app-header></app-header>
<div class="container my-5">
    <div id="ShippingBanner">
        <span><img [src]="IMAGE_URL+'/images/Banner/SHIPPINGBANNER.png'"></span>
    </div>
    <p style="line-height: 22px;">&nbsp;</p>
    <h4 style="line-height: 22px;">HOLIDAY DELAYS:</h4>
    <p style="line-height: 22px;">
        As the holiday season approaches, we want to keep you informed about potential delays with FedEx deliveries.
        While we provide tracking numbers for all orders, please be aware that your package may not be scanned
        immediately by FedEx
        upon leaving our Purium facility. <br><br> This means there may be a gap in tracking updates as we wait for the
        FedEx
        facility to scan your
        package and provide an update. We understand this can be frustrating, especially during busy times, and we truly
        appreciate your
        patience as we navigate these delays.
        <br><br>
        If your tracking number hasn't moved, please give it at least 5 business days before contacting Customer Care for a replacement order. This allows time for any potential delays in transit.
    </p>
    <p style="line-height: 22px;">&nbsp;</p>
    <h4 style="line-height: 22px;">FEDEX DELIVERY MANAGER APP</h4>
    <p style="line-height: 22px;">
        Most of Purium's orders are shipped via FedEx. Download the FedEx Delivery Manager App to take advantage of
        these great features:
    </p>
    <ul style="line-height: 22px;"> 
        <li><strong>Get Tracking Updates:</strong> Receive real-time updates via text message.</li>
        <li><strong>Hold Your Package:</strong> Request that your package be held at a “hold facility.”</li>
        <li><strong>Special Delivery Times:</strong> Choose a specific delivery time (for a fee).</li>
        <li><strong>Add Delivery Notes:</strong> Include special instructions for your delivery.</li>
        <li><strong>Set Vacation Holds:</strong> Schedule a hold for all deliveries while you're away.</li>
        <li><strong>Extended Hold:</strong> Request to hold your package for up to 14 days (must wait 6 days to request another hold).</li>
        <li><strong>Customer Support:</strong> Access the FedEx “Virtual Assistant” and Customer Chat for assistance.</li>
    </ul>
    <p>Make the most of your FedEx deliveries with this convenient app!</p>
    <p style="line-height: 22px;">&nbsp;</p>
    <h4 style="line-height: 22px;">SHIPPING INFORMATION:</h4>
    <p style="line-height: 22px;">&nbsp;</p>
    <p><strong>SHIPPING SCHEDULE AND PROCESSING TIMES:</strong></p>
    <ul style="line-height: 22px;">
        <li>Orders are shipped Monday - Friday</li>
        <li>It’s our goal to process and ship all orders within 2-3 business days.</li>
        <li>Express Shipping Orders placed before 1 pm PT are processed and shipped the same business day.</li>
        <li>Express Shipping Orders placed on the weekend and/or after 1 pm PT are processed and shipped the next business day.</li>
        <li>Any changes made to your order after processing may result in shipping delays.</li>
    </ul>
    <p style="line-height: 22px;">&nbsp;</p>
    <h4>SHIPPING OPTIONS:</h4>
    <p style="text-decoration: underline;"><strong>Continental USA, Hawaii, Alaska, US Territories</strong></p>
    <p><strong>Flat Rate Residential Orders:</strong> Orders ship via FedEx or USPS at Purium’s discretion. Delivery
        time frames range from 1 to 7 business days from the ship date.</p>
    <p><strong>Flat Rate Commercial Orders:</strong> Orders ship via FedEx to commercial addresses during business
        hours. Delivery time frames range from 1 to 7 business days from the ship date.</p>
    <p><strong>Express Shipping:</strong> Orders ship via FedEx Express Shipping. Delivery time frames range from 2 to 4
        business days. This option is available for all physical addresses in the U.S., including Alaska and Hawaii.</p>
    <p>Order Cut-off Time:To ensure same-day shipping, please place your orders by 1 PM PT. Orders
        placed after this time will be shipped the next business day.</p>
    <p>Exclusions: Please note that Express Shipping does not apply to P.O. Box addresses or Monthly Subscription
        Orders.
    </p>

    <p style="line-height: 22px;">&nbsp;</p>
    <p style="text-decoration: underline;"><strong>Canada</strong></p>
    <p>Orders ship via FedEx International Economy. Delivery time frames range from 4 to 7 business days from the ship
        date. Longer shipping times may be experienced in remote areas.</p>
    <p><strong>Customs Fees:</strong> Purium covers all customs clearance and service fees.</p>
        <p>Purium is not responsible for any conversion or other fees associated with your order. All transactions are processed in US Dollars.
        </p>
    <p style="line-height: 22px;">&nbsp;</p>
    <p style="text-decoration: underline;"><strong>Will Call Pick-Up</strong></p>
    <p>This option is available for California residents (see details below).</p>
    <p><strong>Will Call Address:</strong> <br>2610 Homestead Place,<br> Rancho Dominguez, CA 90220</p>
    <p><strong>Will Call Hours:</strong> <br>Monday - Friday,<br> 8am - 5:30pm PT</p>
    <p><strong>Will Call Pick-Up Instructions:</strong></p>
    <ul>
        <li><strong>Arrival:</strong> Please park in the visitor parking area.</li>
        <li><strong>Notification:</strong> Ring the doorbell near the Will Call sign and show your ID.</li>
        <li><strong>Identification:</strong> The customer who placed the order must be present and show ID for pick-up, unless other arrangements have been made through customer care.</li>
    </ul>
    <p><strong>Important:</strong> Orders must be picked up within 3 Business Days of purchase date. If orders are not picked up within 3 business days of the purchase date, the purchase will be canceled, refunded, and re-shelved.</p>
    <br><br>
    <p><strong style="text-decoration: underline;">Shipping Costs:</strong>
        <br>$11.95 Flat Rate S&H in continental US
        <br>$16.95 to Hawaii, Alaska, US Territories
        <br>$19.95 to Canada
        <br><span style="font-weight: 700; font-style: italic;">Save $10 on S&H with orders of $250 or more</span>
        <br>
        <br>
        Express Shipping offered within Continental US. Cost based on weight and destination. No discounts applied.
    </p>
    <p style="line-height: 22px;">&nbsp;</p>
    <h4>Checking Your Order</h4>
    <p>
        When your order arrives, take a moment to check everything carefully. Smaller items can sometimes shift to the bottom of the box, making them easy to miss.
        <br>
        Before throwing away any packing materials, be sure to:<br>
        ✅ Look through the entire box for all items.<br>
        ✅ Compare what you received with the shipping invoice.
        <br>
        If anything is missing from your order, you must let us know within 10 days of delivery.
        <br>
        If you receive a notice that a delivery attempt was made, contact the carrier to schedule anew delivery. If the package is not claimed, it will be returned to Purium.
    </p>
    <p style="line-height: 22px;">&nbsp;</p>
    <h4>DISCLAIMERS</h4>
    <p>All testimonials appearing on this website represent the individual views and positions of their authors and not necessarily the views and positions of Purium Health Products. Consumers should not construe any information herein as medical advice or as a substitute for discussions with a prescribing practitioner or other qualified medical professional. These statements have not been evaluated by the U.S. Food and Drug Administration (FDA). The recommended use of Purium Health Products is not intended to diagnose, treat, cure or prevent any disease or medical condition. Always consult with a professional medical practitioner before taking any dietary supplement, especially if pregnant, nursing, taking prescription medications or under a doctor’s medical care.
    </p>
    <p style="line-height: 22px;">&nbsp;</p>
    <h4>Income Disclaimer</h4>
    <p>
        Any representation or guarantee of earnings would be misleading. As with any business, your results may vary and will be based on your individual capacity, business experience, expertise, level of desire and amount of hours you put into your Purium business activities. Please check the Purium Compensation Plan for Average Earnings per Rank.
        </p>
</div>


<!-- ============================================================ -->

<app-footercomponent></app-footercomponent>

<!-- Bootstrap Script -->
<script src="scrollingtext.js"></script>
<script src="https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/js/bootstrap.bundle.min.js">
</script>
<link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/css/bootstrap.min.css">