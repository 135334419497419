<!-- ======================= PAGE CONTENT ====================================== -->

<app-header></app-header>
<div id="support-page" fxLayout="column">
    <div class="container mb-5" fxLayout="column" fxLayoutAlign="space-evenly center" fxFlex="80"
        style="width:100%;">
        <div style="background-color:#00613E; color: white;width: 100%;">
            <img class="img-fluid" [src]="IMAGE_URL+'/img/Support Banner-1.png'" style="width:inherit;" />
        </div>
        <!-- Support Form -->
        <div fxLayout="column" fxLayoutAlign="space-evenly center" class="container pt-2 mt-2 text-center"
            style="color:#00613E;width: 100%;">
            <h1>SUPPORT FORMS</h1>
            <div class="row justify-content-center mt-5">
                <!-- 1 -->
                <div class="card m-2" style="width: 15rem;">
                    <img class="card-img-top pt-2"
                        src="https://www.puriumcorporate.com/puriumMedia/wp-content/uploads/2023/03/1s.png"
                        onerror="this.onerror=null; this.src='assets/images/documents-support/S3.png';"  alt="Missing or Damaged Products">
                    <div class="card-body d-flex flex-column">
                        <h5 class="card-title">Missing or Damaged Products</h5>
                        <p class="card-text">We take great pride in our work and sometimes mistakes happen.
                            Purium is committed to make it right.</p>
                        <a href="https://pure-premium.typeform.com/to/yAWYqnWE" target="_blank" class="btn mt-auto"
                            style="background-color: #006145; color: white; border-radius: 0;">COMPLETE THIS FORM</a>
                    </div>
                </div>
                <!-- 2 -->
                <div class="card m-2" style="width: 15rem;">
                    <img class="card-img-top pt-2"
                        src="https://www.puriumcorporate.com/puriumMedia/wp-content/uploads/2023/03/4s.png"
                        onerror="this.onerror=null; this.src='assets/images/documents-support/S1.png';"  alt="Account Maintenance">
                    <div class="card-body d-flex flex-column">
                        <h5 class="card-title">Primary Support</h5>
                        <p class="card-text">Do you have questions about your order, Monthly Subscription, or account? Use our
                            Customer Care form for account maintenance, such as editing, cancelling orders, and
                            upgrading.
                        </p>
                        <a href="https://docs.google.com/forms/d/e/1FAIpQLSe9O1GnjPbLUdPQTFk-_aGx66-5Nu1smNICOTgtzFM1Fw3Eqg/viewform"
                            target="_blank" class="btn mt-auto"
                            style="background-color: #006145; color: white; border-radius: 0;">COMPLETE THIS FORM</a>
                    </div>
                </div>
                <!-- 4 -->
                <div class="card m-2" style="width: 15rem;">
                    <img class="card-img-top pt-2"
                        src="https://www.puriumcorporate.com/puriumMedia/wp-content/uploads/2023/03/2s.png"
                        onerror="this.onerror=null; this.src='assets/images/documents-support/S2.png';"  alt="Special Attachments">
                    <div class="card-body d-flex flex-column">
                        <h5 class="card-title">Special Attachments</h5>
                        <p class="card-text">Do you have documentation to submit to Purium? Upload (max of 2) for tax
                            ID,
                            name change, business formation documents, etc., using the above form.</p>
                        <a href="https://pure-premium.typeform.com/to/sAYr0zb3" target="_blank" class="btn mt-auto"
                            style="background-color: #006145; color: white; border-radius: 0;">COMPLETE THIS FORM</a>
                    </div>
                </div>
                <div class="card m-2" style="width: 15rem;">
                    <img class="card-img-top pt-2"
                        src="assets/images/documents-support/complete-form-image.png"
                        alt="Special Attachments">
                    <div class="card-body d-flex flex-column">
                        <h5 class="card-title">Communication Support</h5>
                        <p class="card-text">Not receiving texts and/or emails? Please submit your details here to get you subscribed!</p>
                        <a href="https://pure-premium.typeform.com/to/qflNvkqi" target="_blank" class="btn mt-auto"
                            style="background-color: #006145; color: white; border-radius: 0;">COMPLETE THIS FORM</a>
                    </div>
                </div>
            </div>
        </div>
        <div class="container text-center pt-5 pb-3">
            <h1>Customer Care</h1>
            <br>
            <h5>Monday - Friday <br> 7:00 AM - 5:00 PM PT</h5>
            <p>We are experiencing a heavy volume of inquiries<br> and thank you for your patience.</p>
            <p>Please submit <strong>1 Web Form</strong> or <strong>Phone Call</strong> per issue and<br>share as many
                details as possible for the quickest resolution.
            </p>
            <p>
                888-PHP-ORDER (888-747-6733)
            </p>
            <br>
        </div>
        <div class="container text-center pt-5 pb-3">
            <h1>QUICK SUPPORT</h1>
            <br>
            <mat-accordion>
                <mat-expansion-panel class="border-txt" *ngFor="let faqItem of faqItems; let i = index;">
                    <mat-expansion-panel-header class="header-border">
                        {{ faqItem.question }}
                    </mat-expansion-panel-header>
                    <p style="text-align: left !important;" [innerHTML]="faqItem.answer"></p>
                </mat-expansion-panel>
            </mat-accordion>
            <br>
        </div>
        <div fxLayoutAlign="space-evenly center" class="pt-5 pb-5 mt-5 bg-image" 
        [style.background-image]="'url(' + IMAGE_URL + '/img/support-bg.png)'"
        style="
    max-width:100%;
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    color: white;
    width: 100%;">
            <div class="container text-center pt-5 pb-5">
                <h1>DO YOU HAVE ANY ADDITIONAL QUESTIONS?</h1>
                <br><br>
                <h3>Purium Blogs</h3>
                <p>The Purium blogs contain tons of information about product and business at your fingertips.
                    <br>
                    Use the search bar to find blogs by product name or topics.
                </p>
                <div class="row">
                    <div class="col-6">
                        <a class="btn" href="https://blog.puriumcorp.com/" target="_blank"
                            aria-label="PRODUCT BLOG - Opens in a new tab"
                            style="background-color: #33A744; color: white; border-radius: 0; float: right;">
                            PRODUCT BLOG
                        </a>
                    </div>
                    <div class="col-6">
                        <a class="btn" href="https://www.isharepurium.com/blog/" target="_blank"
                            aria-label="BUSINESS BLOG - Opens in a new tab"
                            style="background-color: #33A744; color: white; border-radius: 0; float: left;">
                            BUSINESS BLOG
                        </a>
                    </div>
                </div>
                <br><br>
                <h3>Purium Lifestyle Transformation Support Group</h3>
                <p>Use the search bar in our Support Group to see how the Purium community has experienced your question
                    or
                    concern.
                </p>
                <a class="btn" href="https://www.facebook.com/groups/PuriumLifestyleTransformation" target="_blank"
                    aria-label="JOIN GROUP - Opens in a new tab"
                    style="background-color: #33A744; color: white; border-radius: 0;">
                    JOIN GROUP
                </a>
                <br><br>
                <h3>Purium Business Group</h3>
                <p>This is a Private Facebook Group dedicated to Purium Brand Partners.<br>
                    Here you will find training material, social media posts for sharing, product information, sale
                    updates,
                    articles, event updates and additional resources to help you grow as a Purium Brand Partner.
                </p>
                <a class="btn" href="https://www.facebook.com/groups/PuriumTribe/" target="_blank"
                    aria-label="JOIN GROUP - Opens in a new tab"
                    style="background-color: #33A744; color: white; border-radius: 0;">
                    JOIN GROUP
                </a>
            </div>
        </div>
    </div>
    <app-footercomponent style="width: 100%;"></app-footercomponent>
</div>
<link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/css/bootstrap.min.css">